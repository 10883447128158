
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';
/**
 * @Description: 日期类函数
 * @author gan
 * @date: 2020/10/15 16:59
 */
class localDate {
  getDefaultTimezoom() {
    // 获取默认时区
    /* if (localStorage.getItem('timezoom') !== undefined && localStorage.getItem('timezoom') !== null) {
     return false;
     } */
    let v = this;
    let localtime = new Date().getTime();
    let universalTime = new Date(v.getUniversalTime(new Date().getTime(), 'fulltime')).getTime();
    let timezoom = parseInt((localtime - universalTime) / 60 / 60 / 1000);
    timezoom >= 0 ? (timezoom = '+' + timezoom) : (timezoom = '-' + timezoom);
    localStorage.setItem('timezoom', timezoom);
    return timezoom;
  }

  getNowTime() {
    // 获取用户设置的时间
    let timezoom = localStorage.getItem('timezoom');
    if (timezoom !== undefined) {
      let diffTime = Number(timezoom) * 60 * 60 * 1000;
      return new Date().getTime() - diffTime;
    } else {
      return new Date().getTime();
    }
  }

  getDateTime(date, type) {
    // 时间格式获取

    let d = new Date(date);
    let year = d.getFullYear();
    let month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
    let hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
    let minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    let seconds = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
    if (type === 'fulltime') {
      return (year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds);
    } else if (type === 'customTime') {
      return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
    } else if (type === 'month') {
      return year + '-' + month;
    } else if (type === 'monthTime') {
      return month + '-' + day;
    } else if (type === 'hourTime') {
      return hours + ':' + minutes;
    } else if (type === 'secondTime') {
      return hours + ':' + minutes + ':' + seconds;
    } else {
      return year + '-' + month + '-' + day;
    }
  }

  // UTC时间
  getUniversalTime(date, type) {
    if (date === null) return;
    let d = new Date(date);
    let year = d.getUTCFullYear();
    let month = d.getUTCMonth() + 1 < 10 ? '0' + (d.getUTCMonth() + 1) : d.getUTCMonth() + 1;
    let day = d.getUTCDate() < 10 ? '0' + d.getUTCDate() : d.getUTCDate();
    let hours = d.getUTCHours() < 10 ? '0' + d.getUTCHours() : d.getUTCHours();
    let minutes = d.getUTCMinutes() < 10 ? '0' + d.getUTCMinutes() : d.getUTCMinutes();
    let seconds = d.getUTCSeconds() < 10 ? '0' + d.getUTCSeconds() : d.getUTCSeconds();
    if (type === 'fulltime') {
      return (year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds);
    } else {
      return year + '-' + month + '-' + day;
    }
  }

  getDataToLocalTime(date, type) {
    // 本地时间
    if (date === null) return null;
    let b;
    try {
      if (date.replace) {
        b = new Date(Date.parse(date.replace(/-/g, '/'))).getTime() + localStorage.getItem('timezoom') * 60 * 60 * 1000;
      }
    } catch (err) {
      b = new Date(date).getTime() + localStorage.getItem('timezoom') * 60 * 60 * 1000;
    }
    let d = new Date(b);
    let year = d.getFullYear();
    let month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
    let hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
    let minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    let seconds = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
    if (type === 'fulltime') {
      return (year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds);
    } else {
      return year + '-' + month + '-' + day;
    }
  }

  dealTime(time, type) {
    // time为世界时间
    if (time) {
      let gmtTimeMs;
      try {
        if (time.replace) {
          gmtTimeMs = new Date(Date.parse(time.replace(/-/g, '/'))).getTime();
        } else {
          gmtTimeMs = new Date(time).getTime();
        }
      } catch (err) {
        gmtTimeMs = new Date(time).getTime();
      }
      let localTime = gmtTimeMs + ((localStorage.getItem('timezoom') || 8) * 60 * 60 * 1000);
      // 转时间格式
      let returnLocalTime = this.getDateTime(localTime, type);
      return returnLocalTime;
    } else {
      return '';
    }
  }

  /**
   * @Description: 获取当前时间
   * @date 2021/10/26 11:33
   * @params:
   */
  getCurrentTime(type, offsetTime) {
    let d = new Date();
    if (offsetTime) {
      let today = new Date(new Date().toLocaleDateString()).getTime();
      d = new Date(today - offsetTime);
    }
    let year = d.getFullYear();
    let month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
    let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
    let hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
    let minutes = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
    let seconds = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();
    if (type === 'fulltime') {
      return (year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds);
    } else {
      return year + '-' + month + '-' + day;
    }
  }

  setTimeHandel(date) {
    /*
     *当天的显示 hh:mm
     本周内的显示
     本周外、今年内的显示
     今年之前的显示 */
    let v = this;
    if (date === null) return null;
    let newDate = new Date(date).getTime();
    let today = new Date(new Date(new Date().toLocaleDateString()).getTime());
    var day = new Date(today).getDay() || 7; // 本周天数
    var year = new Date(today).setMonth(0);
    year = new Date(year).setDate(1);
    if (newDate > new Date(today).getTime()) {
      // 今天
      return v.format('hh:mm', date);
    } else if (newDate > new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1 - day).getTime()) {
      // 本周
      return v.format('w hh:mm', date);
    } else if (newDate > new Date(year).getTime()) {
      // 本年
      return v.format('MM/dd hh:mm', date);
    } else {
      return v.format('yyyy/MM/dd hh:mm', date);
    }
  }

  format(format, date) {
    let v = this;
    if (!date) {
      date = new Date();
    } else {
      date = new Date(date);
    }

    var Week = [
      alias2f085dd487c1465383484787b29fef2c.t('key1001101'), alias2f085dd487c1465383484787b29fef2c.t('key1001102'), alias2f085dd487c1465383484787b29fef2c.t('key1001103'), alias2f085dd487c1465383484787b29fef2c.t('key1001104'), alias2f085dd487c1465383484787b29fef2c.t('key1001105'), alias2f085dd487c1465383484787b29fef2c.t('key1001106'), alias2f085dd487c1465383484787b29fef2c.t('key1001107')
    ];
    // var Week = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    var o = {
      'y+': date.getFullYear(), // year
      'M+': date.getMonth() + 1, // month
      'd+': date.getDate(), // day
      'h+': date.getHours(), // hour
      'H+': date.getHours(), // hour
      'm+': date.getMinutes(), // minute
      's+': date.getSeconds(), // second
      'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
      'S': date.getMilliseconds(), // millisecond
      'w': Week[date.getDay()]
    };
    if (/(y+)/.test(format)) {
      format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
      }
    }
    return format;
  }

  /**
   * @Description: 根据时间戳转换为天时分秒格式
   * @author jing
   * @date 2021/12/29 10:10
   * @params:
   */

  timeStampTime(timeStamp) {
    let days = parseInt(timeStamp / (60 * 60 * 24));
    let hours = parseInt((timeStamp % (60 * 60 * 24)) / (60 * 60));
    let minutes = parseInt((timeStamp % (60 * 60)) / 60);
    let seconds = (timeStamp % 60) / 1000;
    let time = '';
    let daysTime = days ? (days + alias2f085dd487c1465383484787b29fef2c.t('key1001108')) : '';
    let hoursTime = hours ? (hours + alias2f085dd487c1465383484787b29fef2c.t('key1001109')) : '';
    let minutesTime = minutes ? (minutes + alias2f085dd487c1465383484787b29fef2c.t('key1001110')) : '';
    let secondsTime = (seconds && seconds <= 60) ? alias2f085dd487c1465383484787b29fef2c.t('key1001111') : '';
    time = daysTime + hoursTime + minutesTime;
    if (!daysTime && !hoursTime && !minutesTime) {
      time = secondsTime;
    }
    return time;
  }

  /**
   * @Description: 计算创建时间与当前时间的时间差，并将其展示为相应的格式
   * @author jing
   * @date 2023/6/19 16:43
   * @params:
   */

  formatTimeSinceCreation(timer) {
    const currentDate = new Date();
    const createdAt = new Date(timer);
    const diffInMilliseconds = currentDate - createdAt;
    const millisecondsPerSecond = 1000;
    const millisecondsPerMinute = 60 * millisecondsPerSecond;
    const millisecondsPerHour = 60 * millisecondsPerMinute;
    const millisecondsPerDay = 24 * millisecondsPerHour;
    const millisecondsPerWeek = 7 * millisecondsPerDay;
    const millisecondsPerMonth = 30 * millisecondsPerDay;
    const millisecondsPerYear = 365 * millisecondsPerDay;

    if (diffInMilliseconds < millisecondsPerMinute) {
      return alias2f085dd487c1465383484787b29fef2c.t('key1001112');
    } else if (diffInMilliseconds < millisecondsPerHour) {
      const minutes = Math.floor(diffInMilliseconds / millisecondsPerMinute);
      return `${minutes} ${alias2f085dd487c1465383484787b29fef2c.t('key1001095')}`;
    } else if (diffInMilliseconds < millisecondsPerDay) {
      const hours = Math.floor(diffInMilliseconds / millisecondsPerHour);
      return `${hours} ${alias2f085dd487c1465383484787b29fef2c.t('key1001096')}`;
    } else if (diffInMilliseconds < millisecondsPerWeek) {
      const days = Math.floor(diffInMilliseconds / millisecondsPerDay);
      return `${days} ${alias2f085dd487c1465383484787b29fef2c.t('key1001097')}`;
    } else if (diffInMilliseconds < millisecondsPerMonth) {
      const weeks = Math.floor(diffInMilliseconds / millisecondsPerWeek);
      return `${weeks} ${alias2f085dd487c1465383484787b29fef2c.t('key1001098')}`;
    } else if (diffInMilliseconds < millisecondsPerYear) {
      const months = Math.floor(diffInMilliseconds / millisecondsPerMonth);
      return `${months} ${alias2f085dd487c1465383484787b29fef2c.t('key1001099')}`;
    } else {
      const years = Math.floor(diffInMilliseconds / millisecondsPerYear);
      return `${years} ${alias2f085dd487c1465383484787b29fef2c.t('key1001100')}`;
    }
  }
}

let uDate = new localDate();
export default uDate;
